import Client from "../client/Client"
import version from "../version"

function getListIncomeAndOtherAlert(params) {
  return Client.get(`${version}/tenant/dashboard/income-other/`, { params })
}
function getListAvenueByYear(params) {
  return Client.get(`${version}/tenant/dashboard/revenue-by-year/`, { params })
}
function getListScheduleAlert(params) {
  return Client.get(`${version}/tenant/dashboard/schedule-alert/`, { params })
}
function getStudentReferralBonus(params) {
  return Client.get(`${version}/tenant/student/referral-bonus/`, { params })
}

const api = {
  getListIncomeAndOtherAlert,
  getListAvenueByYear,
  getListScheduleAlert,
  getStudentReferralBonus
}
export default api
