import { removeField } from "lib/function"
import Client from "../client/ClientComiru"
import version from "../version"
const resource = version
function getNotificationList(params) {
  return Client.get(`${resource}/notifications`, { params })
}

function getNotificationStatistics(id) {
  return Client.post(`${resource}/notifications/${id}/statistics`)
}

function createNotification(data) {
  return Client.post(`${resource}/notifications`, removeField(data, "id"))
}

function updateNotification(data) {
  return Client.put(`${resource}/notifications/${data?.id}`, removeField(data, "id"))
}

function deleteNotification(id) {
  return Client.delete(`${resource}/notifications/${id}`)
}

function cancelNotification(id) {
  return Client.delete(`${resource}/notifications/${id}/cancel`)
}

function getNotificationDetails(id) {
  return Client.get(`${resource}/notifications/${id}`)
}

const api = {
  getNotificationList,
  getNotificationStatistics,
  createNotification,
  deleteNotification,
  getNotificationDetails,
  updateNotification,
  cancelNotification
}
export default api
