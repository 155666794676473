import Client from "../client/ClientComiru"
import version from "../version"
const resource = version + "/settings"

function getTeachingReport() {
  return Client.get(`${resource}/teaching-report`)
}

function updateTeachingReport(data) {
  return Client.put(`${resource}/teaching-report`, data)
}

function getTeachingReportAlert() {
  return Client.get(`${resource}/teaching-report/alert`)
}

function updateTeachingReportAlert(data) {
  return Client.put(`${resource}/teaching-report/alert`, data)
}

const api = {
  getTeachingReport,
  updateTeachingReport,
  getTeachingReportAlert,
  updateTeachingReportAlert
}
export default api
