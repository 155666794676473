export const myTheme = {
  colorPrimary: "#4DABD1",
  colorText: "#0E2026",
  borderRadius: 8,
  fontFamily: "Lato",
  colorTextPlaceholder: "#646970",
  colorBorder: "#c2c2c2"
}

export const themeColors = [
  { title: "Palette 1", value: 1, color: myTheme.colorPrimary },
  { title: "Palette 2", value: 2, color: "#1B90FF" },
  { title: "Palette 3", value: 3, color: "#7858FF" },
  { title: "Palette 4", value: 4, color: "#049F9A" }
]

export const myFonts = [
  {
    label: "Lato",
    value: "lato",
    href: "https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&display=swap"
  },
  {
    label: "Roboto",
    value: "roboto",
    href: "https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap"
  },
  {
    label: "Noto Sans JP",
    value: "noto-sans-jp",
    href: "https://fonts.googleapis.com/css2?family=Noto+Sans+JP:wght@100..900&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap"
  }
  // { label: "Palette 4", value: 4 }
]
