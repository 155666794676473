import Client from "../client/ClientComiru"
import version from "../version"
const resource = version
function getAttendanceList(params, searchParams) {
  const newParams = { ...searchParams, ...params }

  return Client.get(`${resource}/attendance/`, { params: newParams })
}

function createAttendance(data) {
  return Client.post(`${resource}/attendance`, data)
}

function deleteAttendance(id) {
  return Client.delete(`${resource}/attendance/${id}`)
}

function exportList(params) {
  return Client.post(`${resource}/attendance/csv/`, params)
}

const api = {
  getAttendanceList,
  createAttendance,
  deleteAttendance,
  exportList
}
export default api
