import { useReducer, useMemo, createContext, useContext, useEffect } from "react"
import {
  setLocalProject,
  getBaseDataLocal,
  removeAuthLocal,
  setBaseDataLocal,
  setLocalCommon,
  getLocalCommon
} from "lib/localstorage"
import { removeCookies } from "lib/cookie"
import KEY from "./Const"
import { apiCommon } from "api"

function initialState() {
  let baseData = getBaseDataLocal()
  return {
    areas: [],
    schools: [],
    schoolLevels: getLocalCommon("schoolLevel"),
    schoolYears: [],
    locale: "jp",
    schoolYearsObject: [],
    ...baseData
  }
}

function reducer(state, action) {
  switch (action.type) {
    case KEY.SET_AUTH:
      return { ...state, ...action.value }
    case KEY.SET_PROJECT:
      return { ...state, project: action.value }
    case KEY.SET_AREAS:
      return { ...state, areas: action.value }
    case KEY.SET_SCHOOLS:
      return { ...state, schools: action.value }
    case KEY.SET_SCHOOL_LEVEL:
      return { ...state, schoolLevels: action.value }
    case KEY.SET_SCHOOL_YEAR:
      return { ...state, schoolYears: action.value }
    case KEY.SET_LOCALE:
      return { ...state, locale: action.value }
    case KEY.SET_SCHOOL_YEAR_OBJECT:
      return { ...state, schoolYearsObject: action.value }
    case KEY.LOG_OUT:
      removeCookies()
      removeAuthLocal()
      return initialState()
    default:
      throw new Error()
  }
}

const MyContext = createContext(initialState())
MyContext.displayName = "MyContext"

export function StoreProvider(props) {
  const [state, dispatch] = useReducer(reducer, initialState())

  const setAuthStore = (value) => {
    const currentBaseData = getBaseDataLocal()
    const newValua = { ...currentBaseData, ...value }
    setBaseDataLocal(newValua)
    return dispatch({ type: KEY.SET_AUTH, value })
  }
  const logOut = () => {
    dispatch({ type: KEY.LOG_OUT })
  }

  const setAreas = (value) => {
    setLocalProject(value)
    return dispatch({ type: KEY.SET_AREAS, value })
  }
  const setSchools = (value) => {
    return dispatch({ type: KEY.SET_SCHOOLS, value })
  }
  const setSchoolLevel = (value) => {
    setLocalCommon("schoolLevel", value)
    return dispatch({ type: KEY.SET_SCHOOL_LEVEL, value })
  }
  const setSchoolYear = (value) => {
    setLocalProject(value)
    return dispatch({ type: KEY.SET_SCHOOL_YEAR, value })
  }
  const setLocale = (value) => {
    return dispatch({ type: KEY.SET_LOCALE, value })
  }
  const setSchoolYearsObject = (value) => {
    setLocalProject(value)
    return dispatch({ type: KEY.SET_SCHOOL_YEAR_OBJECT, value })
  }

  const setProject = (value) => {
    setLocalProject(value)
    return dispatch({ type: KEY.SET_PROJECT, value })
  }

  const value = useMemo(
    () => ({
      ...state,
      setAuthStore,
      setProject,
      setAreas,
      setSchools,
      setSchoolLevel,
      setSchoolYear,
      logOut,
      setLocale,
      setSchoolYearsObject
    }),
    [state]
  )
  // console.log({ state })

  useEffect(() => {
    async function get() {
      try {
        const { data } = await apiCommon.getSchoolGrades()
        setSchoolLevel(data)
      } catch (error) {
        console.log(error)
      }
    }

    if (!state.schoolLevels.length && state.access_token) {
      get()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.access_token])

  return <MyContext.Provider value={value} {...props} />
}

const useStore = () => {
  const context = useContext(MyContext)
  if (context === undefined) {
    throw new Error(`useUI must be used within a UIProvider`)
  }
  return context
}

export default useStore
