import { removeField } from "lib/function"
import Client from "../client/ClientComiru"
import version from "../version"
const resource = version
function getTagsList(params) {
  return Client.get(`${resource}/settings/notification/tags`, { params })
}

function createTag(data) {
  return Client.post(`${resource}/settings/notification/tags`, data)
}

function updateTag(data) {
  return Client.put(`${resource}/settings/notification/tags/${data?.id}`, removeField(data, "id"))
}

function deleteTag(id) {
  return Client.delete(`${resource}/settings/notification/tags/${id}`)
}

function getTagsDetails(id) {
  return Client.get(`${resource}/settings/notification/tags/${id}`)
}

const api = {
  getTagsList,
  createTag,
  deleteTag,
  getTagsDetails,
  updateTag
}
export default api
