import Client from "../client/Client"
import version from "../version"
const resource = version + "/tenant/billings"

function gets(params) {
  return Client.get(`${resource}/`, { params })
}
function get(id, params) {
  return Client.get(`${resource}/${id}/`, { params })
}
function create(data) {
  return Client.post(`${resource}/`, data)
}
function update(id, data) {
  return Client.put(`${resource}/${id}/`, data)
}
function remove(id, data) {
  return Client.delete(`${resource}/${id}/`, data)
}
function billingDeposit(data) {
  return Client.post(`${resource}/deposits/`, data)
}
function getDepositHistories(id, params) {
  return Client.get(`${resource}/${id}/deposits/`, { params })
}
function removeDeposit(id) {
  return Client.delete(`${resource}/deposits/${id}/`)
}
function getBillingDate(params) {
  return Client.get(`${resource}/reports/`, { params })
}
function getNote(params) {
  return Client.get(`${resource}/notes/`, { params })
}
function updateBillingDate(data) {
  return Client.post(`${resource}/reports/`, data)
}
function updateBillingNote(data) {
  return Client.post(`${resource}/notes/`, data)
}
function getMonthlyBill(params) {
  return Client.get(`${resource}/monthly-fee/`, { params })
}
function getChargingMethod(params) {
  return Client.get(`${resource}/charging-method/`, { params })
}
function importBank(data) {
  return Client.post(`${resource}/import-deposit/`, data)
}
function exportList(params) {
  return Client.post(`${resource}/export/`, {}, { params })
}

const api = {
  gets,
  get,
  create,
  update,
  remove,
  billingDeposit,
  getDepositHistories,
  removeDeposit,
  getBillingDate,
  getNote,
  updateBillingDate,
  updateBillingNote,
  getMonthlyBill,
  getChargingMethod,
  exportList,
  importBank
}
export default api
