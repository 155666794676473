import Client from "../client/Client"
import version from "../version"
const resource = version + "/tenant/billings/unpaids"
const resourceBilling = version + "/tenant/billings"

function gets(params) {
  return Client.get(`${resource}/`, { params })
}
function get(id, params) {
  return Client.get(`${resource}/${id}/`, { params })
}
function create(data) {
  return Client.post(`${resource}/`, data)
}
function update(id, data) {
  return Client.patch(`${resourceBilling}/${id}/`, data)
}
function remove(id, data) {
  return Client.delete(`${resource}/${id}/`, data)
}
function exportList(params) {
  return Client.post(`${resourceBilling}/export-unpaid/`, {}, { params })
}

const api = {
  gets,
  get,
  create,
  update,
  remove,
  exportList
}
export default api
