import { ConfigProvider, notification } from "antd"
import { StyleProvider } from "@ant-design/cssinjs"
import useStore, { StoreProvider } from "components/ui/Context"
import Routes from "components/Router"
import { Fragment, useEffect, useState } from "react"
import { myTheme } from "lib/theme"
import { applyTheme, getDomainFromUrl } from "lib/function"
import { apiCommon } from "api"
import jp from "antd/locale/ja_JP"
import en from "antd/locale/en_US"
import "./i18n"
import "./App.scss"
import Page403 from "pages/ErrorPage/Page403"
import "dayjs/locale/en"
import "dayjs/locale/ja"

notification.config({
  top: 0,
  duration: 3
})
function App() {
  const { access_token } = useStore()
  const [showErrorPage, setShowErrorPage] = useState(null)

  async function checkExistedDomain() {
    try {
      await apiCommon.checkExistedDomain({
        subdomain: getDomainFromUrl(window.location.href)
      })
      setShowErrorPage(false)
    } catch (e) {
      const { response } = e
      setShowErrorPage(true)
      console.log(response)
    }
  }

  const isLocal = window.location.origin === "http://localhost:3000"

  useEffect(() => {
    window.addEventListener("error", (e) => {
      // prompt user to confirm refresh
      if (/Loading chunk [\d]+ failed/.test(e.message)) {
        window.location.reload()
      }
    })

    if (!access_token && !isLocal) {
      checkExistedDomain()
    } else {
      setShowErrorPage(false)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <>
      {showErrorPage === true && <Page403 />}
      {showErrorPage === false && (
        <StyleProvider hashPriority="high">
          <StoreProvider>
            <Main />
          </StoreProvider>
        </StyleProvider>
      )}
    </>
  )
}

export default App

function Main() {
  const { settingTheme, logOut, locale } = useStore()

  let colorPrimary = settingTheme?.color || myTheme.colorPrimary

  useEffect(() => {
    applyTheme(settingTheme)
  }, [settingTheme])

  useEffect(() => {
    function checkToken(e) {
      if (e.key === "isSignIn") {
        if (e.oldValue === "1" && e.newValue !== "1") {
          logOut()
          window.location.href = "/login"
        }
        if (e.oldValue !== "1" && e.newValue === "1") window.location.href = "/dashboard"
      }
    }
    window.addEventListener("storage", checkToken)
    return () => {
      window.removeEventListener("storage", checkToken)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <Fragment>
      <ConfigProvider
        locale={locale === "jp" ? jp : en}
        theme={{
          token: {
            colorPrimary: colorPrimary,
            borderRadius: myTheme.borderRadius,
            borderRadiusSM: myTheme.borderRadius,
            borderRadiusLG: myTheme.borderRadius,
            colorBorder: myTheme.colorBorder,
            fontFamily: settingTheme?.fontFamily || myTheme.fontFamily,
            controlHeightSM: 28,
            controlHeight: 40,
            controlHeightLG: 48,
            colorText: myTheme.colorText,
            fontSize: 16,
            colorTextPlaceholder: myTheme.colorTextPlaceholder,
            colorLink: "#0D7FAB"
          },
          components: {
            Breadcrumb: {
              lastItemColor: colorPrimary,
              itemColor: myTheme.colorText,
              fontSize: 14
            },
            Form: {
              labelFontSize: 16,
              fontSize: 16,
              labelColor: myTheme.colorLabel,
              verticalLabelPadding: "0 0 4px",
              itemMarginBottom: 16
            },
            Button: {
              contentFontSizeSM: 14,
              contentFontSize: 16,
              paddingInlineSM: 11,
              defaultColor: "#646970"
            },
            Table: {
              cellFontSizeSM: 14,
              rowSelectedBg: "#BFE1EE",
              rowSelectedHoverBg: "#BFE1EE",
              headerBg: "white"
            },
            Checkbox: {
              borderRadiusSM: 4
            },
            Input: {
              paddingInline: "12px"
            },
            Radio: {
              radioSize: 20,
              dotSize: 12
            }
          }
        }}
      >
        <Routes />
        <div id="modal-root"></div>
      </ConfigProvider>
    </Fragment>
  )
}
