import Client from "../client/Client"
import version from "../version"
const resource = version + "/tenant/student/contract"

function gets(params) {
  return Client.get(`${resource}/`, { params })
}
function getsMonthlyContracts(params) {
  return Client.get(`${resource}/monthly/`, { params })
}
function get(id) {
  return Client.get(`${resource}/${id}/`)
}
function calculateAmount(data) {
  return Client.post(`${resource}/calculate-fee/`, data)
}
function create(data) {
  return Client.post(`${resource}/`, data)
}
function update(id, data) {
  return Client.put(`${resource}/${id}/`, data)
}
function createDiscountPrivileges(data) {
  return Client.post(`${resource}/discount-privilege/`, data)
}
function updateDiscountPrivileges(id, data) {
  return Client.put(`${resource}/discount-privilege/${id}/`, data)
}
function remove(id) {
  return Client.delete(`${resource}/${id}/`)
}
function removeDiscount(id) {
  return Client.delete(`${resource}/discount-privilege/${id}/`)
}

function getContracts() {
  return Client.get(`${version}/tenant/contract-courses/`)
}
const api = {
  gets,
  getsMonthlyContracts,
  get,
  calculateAmount,
  create,
  update,
  remove,
  removeDiscount,
  getContracts,
  createDiscountPrivileges,
  updateDiscountPrivileges
}
export default api
