import Client from "../client/Client"
import version from "../version"
const resource = version + "/tenant/salary"

function gets(params) {
  return Client.get(`${resource}/`, { params })
}
function getsBySchool(params) {
  return Client.get(`${resource}/school-group/`, { params })
}
function getsByTeacher(params) {
  return Client.get(`${resource}/`, { params })
}
function getsByCost(params) {
  return Client.get(`${resource}/cost-comparison/`, { params })
}
function getsByChargingMethod(params) {
  return Client.get(`${resource}/charging-method-comparison/`, { params })
}
function getsCalendarTeacher(id, params) {
  return Client.get(`${version}/tenant/calendar/teachers/${id}/count-by-lesson/`, { params })
}
function getsSalaryIncrease(params) {
  return Client.get(`${version}/tenant/teacher/promotion-history/`, { params })
}
function get(id) {
  return Client.get(`${resource}/${id}/`)
}
function getPaymentDate(params) {
  return Client.get(`${resource}/reports/`, { params })
}
function create(data) {
  return Client.post(`${resource}/`, data)
}
function update(id, data) {
  return Client.put(`${resource}/${id}/`, data)
}
function remove(id) {
  return Client.delete(`${resource}/${id}/`)
}
function exportList(data) {
  return Client.post(`${resource}/export-csv/`, data)
}
function exportPDF(data) {
  return Client.post(`${resource}/bulk-output-pdf/`, data)
}
function getsCostComparison(params) {
  return Client.get(`${resource}/cost-comparison/`, { params })
}
function sendEmail(data) {
  return Client.post(`${resource}/send-email/`, data)
}
function updatePaymentDate(data) {
  return Client.post(`${resource}/reports/`, data)
}

const api = {
  gets,
  get,
  getsBySchool,
  getsByTeacher,
  getsByCost,
  getsByChargingMethod,
  getsCalendarTeacher,
  getsSalaryIncrease,
  getPaymentDate,
  create,
  update,
  updatePaymentDate,
  remove,
  exportList,
  exportPDF,
  getsCostComparison,
  sendEmail
}
export default api
